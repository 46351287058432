<template>
  <div class="row mt-60">
    <loader-component v-if="loading"/>
    <div class="col-md-9">
      <ul class="new-service-list">
        <li><a href="javascript:void(0)" :class="filter.patientType == 'booked_appointment'?'active':''" @click="filter.patientType = 'booked_appointment'; getReports()">Consultation</a></li>
        <li><a href="javascript:void(0)" :class="filter.patientType == 'Zero Clubfoot'?'active':''" @click="filter.patientType = 'Zero Clubfoot'; getReports()">Zero Clubfoot</a></li>
        <li><a href="javascript:void(0)" :class="filter.patientType == 'Physiotherapy'?'active':''" @click="filter.patientType = 'Physiotherapy'; getReports()">Physiotherapy</a></li>
        <li><a href="javascript:void(0)" :class="filter.patientType == 'Dental'?'active':''" @click="filter.patientType = 'Dental'; getReports()">Dental Care</a></li>
      </ul>
    </div>
    <div class="col-md-3">
      <ul class="filter-list-new">

        <li>
          <b-button class="coll-btn" id="popover-month" @click="selectFilter('Month')">{{ filter.quarter?'Q'+filter.quarter:filter.month?filter.month :'Month' }} <i class="fas fa-chevron-down"></i></b-button>
          <b-popover target="popover-month" placement="bottomleft" :show="showMonthFilter">
            <ul class="month-selection-list" style="width: 340px;">
              <li v-for="(item, key) in months" :key="key"> 
                <a v-if="!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text)" href="javascript:void(0)" :class="item.text == filter.month?'active':''" @click="selectPeriod('monthly',item.text); showMonthFilter=false; getReports()">{{ item.text }}</a>
                <a v-else href="javascript:void(0)" :class="item.value == filter.quarter?'active':''" @click="selectPeriod('quarterly',item.value); showMonthFilter=false; getReports()">{{ item.text }}</a>
              </li>
            </ul>
          </b-popover>
        </li>

        <li>
          <b-button class="coll-btn" id="popover-year" @click="selectFilter('Year')">{{ filter.year }} <i class="fas fa-chevron-down"></i></b-button>
          <b-popover target="popover-year" placement="bottomleft" :show="showYearFilter">
            <ul class="year-selection-list" style="width: 280px;">
              <li>
                <a href="javascript:void(0)" :class="filter.year == year ?'active':''" @click="filter.year = year; getReports()" v-for="(year, index) in years"  :key="index">{{  year }}</a>
              </li>
            </ul>
          </b-popover>
        </li>
        
      </ul>
    </div>

    <div class="col-md-5">
      <div class="c-card">
        <div class="filter-section">
          <form>
            <ul class="new-center-list">
              <li>
                  <a href="javascript:void(0)" :class="filter.center==''?'active':''" @click="filter.center = ''; showCenterFilter=false; getReports()">All</a>
              </li>
              <li v-for="(item, key) in centers" :key="key">
                <a href="javascript:void(0)" :class="item.short_form == filter.center?'active':''" @click="filter.center = item.short_form; showCenterFilter=false; getReports()">{{ item.short_form }}</a>
              </li>
            </ul>

            <ul class="tab-list">
              <li @click="activeTab = 'patient'" :class="{'active': activeTab == 'patient'}">Patient</li>
              <li @click="activeTab = 'income'" :class="{'active': activeTab == 'income'}">Income</li>
              <li>COS</li>
            </ul>

            <div class="c-tab-content" v-if="activeTab == 'patient'">
              <ul class="c-tab-item" v-if="filter.patientType == 'Zero Clubfoot'">
                <li>
                  <span class="service-name">New Registration</span>
                </li>
                <li>
                  <span class="pt-count">{{ getServiceCount('registration', 'patient_count') }}</span>
                  <span class="pt-label">Pt.</span>
                </li>
                <li>
                  <span class="pt-count">{{ getServiceCount('registration', 'total_foot') }}</span>
                  <span class="pt-label">Ft.</span>
                </li>
              </ul>

              <ul class="c-progressbar" v-if="filter.patientType == 'Zero Clubfoot'">
                <li v-for="(item, key) in age_wise_service.registration" :key="key" :title="`${agePackages[key]} - ${numDifferentiation(getAgeServiceCount(item.max_age, 'registration', 'patient_count'))}`" v-if="getAgeServiceCount(item.max_age, 'registration', 'patient_count') > 0" :style="{ 'width': (getAgeServiceCount('', 'registration', 'patient_count'))?((getAgeServiceCount(item.max_age, 'registration', 'patient_count')*100)/getAgeServiceCount('', 'registration', 'patient_count'))+ '%':0 + '%', 'background-color': ageColors[key]}">
                  <span>{{ agePackages[key] }}-{{ numDifferentiation(getAgeServiceCount(item.max_age, 'registration', 'patient_count')) }}</span>
                </li>
              </ul>

              <ul class="c-tab-item">
                <li>
                  <span class="service-name">{{ filter.patientType == 'booked_appointment'?'Consultations':'Treatment Phase' }}</span>
                  <span class="target-text" v-if="filter.patientType == 'Zero Clubfoot' || filter.patientType == 'Physiotherapy' || filter.patientType == 'Dental'">Target: {{ getTarget('Treatment', 'total_target') }}</span>
                </li>
                <li>
                  <span class="pt-count">{{ getServiceCount('treatment', 'patient_count') }}</span>
                  <span class="pt-label">Pt.</span>
                  <span class="target-text" v-if="filter.patientType == 'Zero Clubfoot' || filter.patientType == 'Physiotherapy' || filter.patientType == 'Dental'">Ach: {{ (getTarget('Treatment', 'total_target'))?Math.round((getServiceCount('treatment', 'patient_count')*100)/getTarget('Treatment', 'total_target')):0 }}%</span>
                </li>
                <li>
                  <span class="pt-count" v-if="filter.patientType == 'Zero Clubfoot'">{{ getServiceCount('treatment', 'foot_count') }}</span>
                  <span class="pt-count" v-else-if="filter.patientType == 'Physiotherapy'">{{ getServiceCount('treatment', 'session_count') }}</span>
                  <span class="pt-count" v-else-if="filter.patientType == 'Dental'">{{ getServiceCount('treatment', 'service_count') }}</span>
                  <span class="pt-label" v-if="filter.patientType == 'Zero Clubfoot'">Ft.</span>
                  <span class="pt-label" v-else-if="filter.patientType == 'Physiotherapy'">Se.</span>
                  <span class="pt-label" v-else-if="filter.patientType == 'Dental'">Pro.</span>
                </li>
              </ul>

              <ul class="c-progressbar" v-if="filter.patientType == 'Zero Clubfoot'">
                <li v-for="(item, key) in age_wise_service.treatment" :key="key" :title="`${agePackages[key]} - ${numDifferentiation(getAgeServiceCount(item.max_age, 'treatment', 'patient_count'))}`" v-if="getAgeServiceCount(item.max_age, 'treatment', 'patient_count') > 0" :style="{ 'width': (getAgeServiceCount('', 'treatment', 'patient_count'))?((getAgeServiceCount(item.max_age, 'treatment', 'patient_count')*100)/getAgeServiceCount('', 'treatment', 'patient_count'))+ '%':0 + '%', 'background-color': ageColors[key]}">
                  <span>{{ agePackages[key] }}-{{ getAgeServiceCount(item.max_age, 'treatment', 'patient_count') }}</span>
                </li>
              </ul>


              <ul class="c-tab-item" v-if="filter.patientType == 'Zero Clubfoot' || filter.patientType == 'Physiotherapy' || filter.patientType == 'Dental'">
                <li>
                  <span class="service-name">Follow-up Phase</span>
                  <span class="target-text" v-if="filter.patientType == 'Zero Clubfoot'">Target: {{ getTarget('Followup', 'total_target') }}</span>
                </li>
                <li>
                  <span class="pt-count">{{ getServiceCount('followup', 'patient_count') }}</span>
                  <span class="pt-label">Pt.</span>
                  <span class="target-text"  v-if="filter.patientType == 'Zero Clubfoot'">Ach: {{ (getTarget('Followup', 'total_target'))?Math.round((getServiceCount('followup', 'patient_count')*100)/getTarget('Followup', 'total_target')):0 }}%</span>
                </li>
                <li>
                  <span class="pt-count" v-if="filter.patientType == 'Zero Clubfoot'">{{ getServiceCount('followup', 'foot_count') }}</span>
                  <span class="pt-count" v-else-if="filter.patientType == 'Physiotherapy'">{{ getServiceCount('followup', 'session_count') }}</span>
                  <span class="pt-count" v-else-if="filter.patientType == 'Dental'">{{ getServiceCount('followup', 'service_count') }}</span>
                  <span class="pt-label" v-if="filter.patientType == 'Zero Clubfoot'">Ft.</span>
                  <span class="pt-label" v-else-if="filter.patientType == 'Physiotherapy'">Se.</span>
                  <span class="pt-label" v-else-if="filter.patientType == 'Dental'">Pro.</span>
                </li>
              </ul>

            </div>

            <div class="c-tab-content" v-if="activeTab == 'income'">
              <ul class="c-tab-item">
                <li>
                  <span class="service-name">{{ filter.patientType == 'booked_appointment'?'Consultations':'Treatment Phase' }}</span>
                  <span class="target-text" v-if="filter.patientType == 'Zero Clubfoot' || filter.patientType == 'Physiotherapy' || filter.patientType == 'Dental'">Trgt: {{ numDifferentiation(getTarget('Treatment', 'total_income')) }}</span>
                </li>
                <li>
                  <span class="money-count"><small>Tk.</small>{{ getServiceCount('treatment', 'income') }}</span>
                  <span class="target-text" v-if="filter.patientType == 'Zero Clubfoot' || filter.patientType == 'Physiotherapy' || filter.patientType == 'Dental'">Ach: {{ (getTarget('Treatment', 'total_income'))?Math.round((getServiceCount('treatment', 'income')*100)/getTarget('Treatment', 'total_income')):0 }}%</span>
                </li>
                <li>
                  <span class="money-count"><small>Tk.</small>{{  (getServiceCount('treatment', 'income'))?Math.round(getServiceCount('treatment', 'income')/getServiceCount('treatment', 'patient_count')):0 }}</span>
                  <span class="target-text">Per Pt Avg Income</span>
                </li>
              </ul>

              <ul class="c-progressbar" v-if="filter.patientType == 'Zero Clubfoot'">
                <li v-for="(item, key) in age_wise_service.treatment" :key="key" :title="`${agePackages[key]} - ${numDifferentiation(getAgeServiceCount(item.max_age, 'treatment', 'total_income'))}`" v-if="getAgeServiceCount(item.max_age, 'treatment', 'total_income') > 0" :style="{ 'width': (getAgeServiceCount('', 'treatment', 'total_income'))?((getAgeServiceCount(item.max_age, 'treatment', 'total_income')*100)/getAgeServiceCount('', 'treatment', 'total_income'))+ '%':0 + '%', 'background-color': ageColors[key]}">
                  <span>{{ agePackages[key] }}-{{ numDifferentiation(getAgeServiceCount(item.max_age, 'treatment', 'total_income')) }}</span>
                </li>
              </ul>

              <ul class="c-tab-item" v-if="filter.patientType == 'Zero Clubfoot' || filter.patientType == 'Physiotherapy' || filter.patientType == 'Dental'">
                 <li>
                  <span class="service-name">Follow-up Phase</span>
                  <span class="target-text" v-if="filter.patientType == 'Zero Clubfoot'">Trgt: {{ numDifferentiation(getTarget('Followup', 'total_income')) }}</span>
                </li>
                <li>
                  <span class="money-count"><small>Tk.</small>{{ getServiceCount('followup', 'income') }}</span>
                  <span class="target-text" v-if="filter.patientType == 'Zero Clubfoot'">Ach: {{ (getTarget('Followup', 'total_income'))?Math.round((getServiceCount('followup', 'income')*100)/getTarget('Followup', 'total_income')):0 }}%</span>
                </li>
                <li>
                  <span class="money-count"><small>Tk.</small>{{  (getServiceCount('followup', 'income'))?Math.round(getServiceCount('followup', 'income')/getServiceCount('followup', 'patient_count')):0 }}</span>
                  <span class="target-text">Per Pt Avg Income</span>
                </li>
              </ul>



              <ul class="c-tab-item" v-if="filter.patientType == 'Zero Clubfoot' || filter.patientType == 'Physiotherapy' || filter.patientType == 'Dental'">
                <li>
                  <span class="service-name">Total</span>
                  <span class="target-text">Trgt: {{ numDifferentiation(getTarget('Followup', 'total_income') + getTarget('Treatment', 'total_income')) }}</span>
                </li>
                <li>
                  <span class="money-count"><small>Tk.</small>{{ getServiceCount('followup', 'income') + getServiceCount('treatment', 'income') }}</span>
                  <span class="target-text">Ach: {{ (getTarget('Followup', 'total_income') || getTarget('Treatment', 'total_income'))?Math.round(((getServiceCount('followup', 'income') + getServiceCount('treatment', 'income'))*100)/(getTarget('Followup', 'total_income') + getTarget('Treatment', 'total_income'))):0 }}%</span>
                </li>
                <li>
                  <span class="money-count"><small>Tk.</small>{{  (getServiceCount('followup', 'income') || getServiceCount('treatment', 'income'))?Math.round((getServiceCount('followup', 'income') + getServiceCount('treatment', 'income'))/(getServiceCount('followup', 'patient_count') + getServiceCount('treatment', 'patient_count'))):0 }}</span>
                  <span class="target-text">Per Pt Avg Income</span>
                </li>
              </ul>

              <ul class="c-progressbar" v-if="filter.patientType == 'Zero Clubfoot'">
                <li v-for="(item, key) in age_wise_service.treatment" :key="key" :title="`${agePackages[key]} - ${numDifferentiation(getAgeServiceCount(item.max_age, 'treatment', 'total_income'))}`" v-if="getAgeServiceCount(item.max_age, 'treatment', 'total_income') > 0" :style="{ 'width': (getAgeServiceCount('', 'treatment', 'total_income'))?((getAgeServiceCount(item.max_age, 'treatment', 'total_income')*100)/getAgeServiceCount('', 'treatment', 'total_income'))+ '%':0 + '%', 'background-color': ageColors[key]}">
                  <span>{{ agePackages[key] }}-{{ numDifferentiation(getAgeServiceCount(item.max_age, 'treatment', 'total_income')) }}</span>
                </li>
              </ul>

            </div>

          </form>

          <!-- <b-dropdown id="dropdown-1" :text="month" class="m-md-2 w-100">
            <b-row>
              <b-col cols="4">
                <div>
                  <b-button pill class="my-2" size="sm">Q1</b-button>
                </div>
                <div>
                  <b-button pill class="my-2" size="sm">Q2</b-button>
                </div>
                <div>
                  <b-button pill class="my-2" size="sm">Q3</b-button>
                </div>
                <div>
                  <b-button pill class="my-2" size="sm">Q4</b-button>
                </div>
              </b-col>
              <b-col cols="8">
                <div>
                  <b-button pill v-for="(month,index) in months" :key="index" class="m-2" size="sm">{{
                      month.text
                    }}
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </b-dropdown>
          <form class="text-center">
            <b-tabs content-class="mt-3" align="center">
              <b-tab :title="month">
                <b-row>
                  <b-col cols="3">
                    <b-button pill class="my-2" size="sm">Q1</b-button>
                    <b-button pill class="my-2" size="sm">Q2</b-button>
                    <b-button pill class="my-2" size="sm">Q3</b-button>
                    <b-button pill class="my-2" size="sm">Q4</b-button>

                  </b-col>
                  <b-col cols="9">
                    <div>
                      <b-button pill v-for="(month,index) in months" :key="index" class="m-2" size="sm">{{
                          month.text
                        }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="All Center">
                <b-button pill class="my-2 mx-5" size="sm">CTG</b-button>
                <b-button pill class="my-2 mx-5" size="sm">CM</b-button>
                <b-button pill class="my-2 mx-5" size="sm">BB</b-button>
              </b-tab>
              <b-tab title="Zero Clubfoot">
                <b-button pill class="my-2 mx-5" size="sm">Zero Clubfoot</b-button>
                <b-button pill class="my-2 mx-5" size="sm">Physiotherapy</b-button>
              </b-tab>
            </b-tabs>
          </form> -->
        </div>
        <!-- <div class="target-content">
          <h2 class="content-title">Treatment Phase (TP)</h2>
          <ul>
            <li
                v-for="(service, service_index) in services"
                :key="service_index"
            >
              <div
                  v-if="
                  ['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'Plaster Material',
                  'therapy_session', 'therapy_appointment', 'assistive_device', 'Dental', 'dental_appointment'].includes(service.product_type)
                  && (checkTargetExists(service.product_type) || filter.patientType == 'Plaster Material')
                "
              >
                <div class="l-label">
                  <label>{{ labelUpdate(service.product_type) }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{ getServiceCount(service.product_type, service.patient_count) }}</span> /
                  <span class="target">{{ getTarget(service.product_type) }}</span>
                </div>
                <b-progress
                    :max="max"
                    height="25px"
                    :variant="calculateProgress(getServiceCount(service.product_type, service.patient_count), getTarget(service.product_type))>=100?'success':'warning'"
                    :precision="0"
                    show-progress
                    class="mb-3 brd-15 ftb-15 w-100"
                >
                  <b-progress-bar :value="calculateProgress(getServiceCount(service.product_type, service.patient_count), getTarget(service.product_type))">
                    <span>
                      <strong>{{ calculateProgress(getServiceCount(service.product_type, service.patient_count), getTarget(service.product_type)) }}%</strong></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
            </li>
          </ul>

          <h2 class="content-title">Follow-up Phase (FP)</h2>
          <ul>
            <li
                v-for="(service, service_index) in services"
                :key="service_index"
            >
              <div
                  v-if="
                  ['clubfoot_appointment', 'brace', 'therapy_session_fp', 'therapy_appointment_fp', 'assistive_device_fp', 'Dental_fp', 'dental_appointment_fp'].includes(service.product_type) && (checkTargetExists(service.product_type) || filter.patientType == 'Dental' || filter.patientType == 'Physiotherapy')
                "
              >
                <div class="l-label">
                  <label>{{ labelUpdate(service.product_type) }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{ service.patient_count }}</span> <span v-if="getTarget(service.product_type)">/</span>
                  <span class="target" v-if="getTarget(service.product_type)">{{ getTarget(service.product_type) }}</span>
                </div>
                <b-progress
                    :max="max"
                    height="25px"
                    :variant="calculateProgress(service.patient_count, getTarget(service.product_type))>=100?'success':'warning'"
                    :precision="0"
                    show-progress
                    class="mb-3 brd-15 ftb-15 w-100"
                >
                <b-progress-bar :value="calculateProgress(service.patient_count, getTarget(service.product_type))">
                    <span>
                      <strong>{{ calculateProgress(service.patient_count, getTarget(service.product_type)) }}%</strong></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
            </li>
          </ul>
        </div> -->
      </div>
    </div>

    <div class="col-md-7">
      <div class="c-card p-7">
        <div class="patient-income-table tableFixHead">
          <table class="timeline-tbl">
            <thead>
            <tr>
              <th>Timeline</th>
              <th>Services</th>
              <th>TP Income</th>
              <th>FP Income</th>
              <th>Total Income</th>
            </tr>
            </thead>

            <tbody v-for="(data, index) in table_data" :key="index" :class="data.expand?'expanded-row':''">
              <tr v-if="!data.expand">
                <td @click="handleRowClicked(index, data.date)" style="cursor:pointer;">
                  {{ data.isToday && filter.period == 'monthly'?'Today':dateFormat(data.date) }}
                </td>
                <td>{{ data.service_count }}</td>
                <!-- <td>{{ data.new_patient }}</td> -->
                <td>{{ numberFormat(data.tp_income) }}</td>
                <td>{{ numberFormat(data.fp_income) }}</td>
                <td>{{ numberFormat(data.tp_income + data.fp_income) }}</td>
              </tr>


              <tr v-if="data.expand && data.services && data.services.length">
                <td style="vertical-align: top !important; cursor:pointer;" @click="handleRowClicked(index, data.date)">
                  <strong>{{ data.isToday && filter.period == 'monthly'?'Today':dateFormat(data.date) }}</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

            <tr v-if="data.expand && data.services && data.services.length" v-for="(service, sIndex) in data.services" :key="sIndex">
              <td>
                {{ labelUpdate(service.product_type) }}
              </td>
              <!-- <td></td> -->
              <td>{{ service.patient_count }}</td>
              <td>
                <span v-if="['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'Ponseti (Fasciotomy)', 'registration_fee', 'therapy_appointment', 'clubfoot_appointment', 'therapy_session', 'dental_appointment', 'Dental', 'Plaster Material', 'booked_appointment', 'brace'].includes(service.product_type)">{{ numberFormat(service.total_income) }}</span>
              </td>
              <td>
                <span v-if="!['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'Ponseti (Fasciotomy)', 'registration_fee', 'therapy_appointment', 'clubfoot_appointment', 'therapy_session', 'dental_appointment', 'Dental', 'Plaster Material', 'booked_appointment', 'brace'].includes(service.product_type)">{{ numberFormat(service.total_income) }}</span>
              </td>
              <td>{{ numberFormat(service.total_income) }}</td>
            </tr>
            <tr v-if="data.expand && data.services && data.services.length" class="expanded-total-row">
              <td>
                <ul>
                  <li>
                    <strong>Total</strong>
                  </li>
                  <li>{{ (filter.period == 'monthly')?'Yesterday':'Last Month' }}</li>
                </ul>
              </td>
              <td>{{ data.service_count }}</td>
              <td>
                <ul>
                  <li>
                    <strong>{{ numberFormat(data.tp_income) }}</strong>
                  </li>
                  <li>
                    <span :class="calculatePercentage(data.tp_income, data.prev_day.tp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(data.tp_income, data.prev_day.tp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(data.tp_income, data.prev_day.tp_income) }}%</span
                  >
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <strong>{{ numberFormat(data.fp_income) }}</strong>
                  </li>
                  <li>
                    <span :class="calculatePercentage(data.fp_income, data.prev_day.fp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(data.fp_income, data.prev_day.fp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(data.fp_income, data.prev_day.fp_income) }}%</span
                  >
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <strong>{{ numberFormat(data.tp_income + data.fp_income) }}</strong>
                  </li>
                  <li>
                    <span :class="calculatePercentage(data.tp_income + data.fp_income, data.prev_day.tp_income + data.prev_day.fp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(data.tp_income + data.fp_income, data.prev_day.tp_income + data.prev_day.fp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(data.tp_income + data.fp_income, data.prev_day.tp_income + data.prev_day.fp_income) }}%</span
                  >
                  </li>
                </ul>
              </td>
            </tr>

            <tr v-if="data.expand && data.services && !data.services.length">
              <td>
                <strong>{{ data.isToday && filter.period == 'monthly'?'Today':dateFormat(data.date) }}</strong>
              </td>
              <td colspan="4">
                <strong>Data not available</strong>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>

        <div class="summary-wrapper">
          <table class="summary-tbl">
            <thead>
            <tr>
              <th style="width: 14%;">{{ (filter.period == 'monthly')?filter.month+' '+filter.year: 'Q'+filter.quarter+' '+filter.year }} </th>
              <th style="width: 20%; text-align: center;">{{  incomeCalculate('service_count') }}</th>
              <th style="width: 26%; text-align: center;">{{  numberFormat(incomeCalculate('tp_income')) }}</th>
              <th style="width: 20%; text-align: center;">{{  numberFormat(incomeCalculate('fp_income')) }}</th>
              <th style="width: 20%; text-align: center;">{{ numberFormat(incomeCalculate('tp_income') + incomeCalculate('fp_income')) }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width: 14%;">{{ (filter.period == 'monthly')?'Last Month': 'Last Quarter'}}</td>
              <td style="width: 20%; text-align: center;">
                  <!-- <span :class="calculatePercentage(incomeCalculate('new_patient'), lastMonthData.new_patient) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(incomeCalculate('new_patient'), lastMonthData.new_patient) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(incomeCalculate('new_patient'), lastMonthData.new_patient) }}%</span
                  > -->
              </td>
              <td style="width: 18%; text-align: center;">
                  <span :class="calculatePercentage(incomeCalculate('tp_income'), lastMonthData.tp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(incomeCalculate('tp_income'), lastMonthData.tp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(incomeCalculate('tp_income'), lastMonthData.tp_income) }}%</span
                  >
              </td>
              <td style="width: 20%; text-align: center;">
                  <span :class="calculatePercentage(incomeCalculate('fp_income'), lastMonthData.fp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(incomeCalculate('fp_income'), lastMonthData.fp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(incomeCalculate('fp_income'), lastMonthData.fp_income) }}%</span
                  >
              </td>
              <td style="width: 28%; text-align: center;">
                  <span :class="calculatePercentage(incomeCalculate('tp_income') + incomeCalculate('fp_income'), lastMonthData.fp_income + lastMonthData.tp_income) <= 0? 'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(incomeCalculate('tp_income') + incomeCalculate('fp_income'), lastMonthData.fp_income + lastMonthData.tp_income) <= 0? 'fa-chevron-down':'fa-chevron-up'"></i>
                  {{ calculatePercentage(incomeCalculate('tp_income') + incomeCalculate('fp_income'), lastMonthData.fp_income + lastMonthData.tp_income) }}
                  %</span
                  >
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "OCCReportView",
  data() {
    let all_months = [
      {
        text: "Q1",
        value: "1"
      },
      {
        text: "Jan",
        value: "January",
      },
      {
        text: "Feb",
        value: "February",
      },
      {
        text: "Mar",
        value: "March",
      },
      {
        text: "Q2",
        value: "2"
      },
      {
        text: "Apr",
        value: "April",
      },
      {
        text: "May",
        value: "May",
      },
      {
        text: "Jun",
        value: "June",
      },
      {
        text: "Q3",
        value: "3"
      },
      {
        text: "Jul",
        value: "July",
      },
      {
        text: "Aug",
        value: "August",
      },
      {
        text: "Sep",
        value: "September",
      },
      {
        text: "Q4",
        value: "4"
      },
      {
        text: "Oct",
        value: "October",
      },
      {
        text: "Nov",
        value: "November",
      },
      {
        text: "Dec",
        value: "December",
      },
    ];
    return {
      ageColors: ['#F9AA1A', '#FED604', '#66F757', '#cae342'],
      agePackages: ['A', 'B', 'C', 'D', 'E', 'F'],
      loading: false,
      activeTab: 'patient',
      value: 88,
      max: 100,
      months: all_months,
      filter: {
        period: 'monthly',
        month: '',
        center: '',
        patientType: 'Zero Clubfoot',
        year: new Date().getFullYear(),
        quarter: ''
      },
      month: all_months[new Date().getMonth()].value,
      start_year: 2023,
      year: new Date().getFullYear(),
      years: [],
      table_data: [],
      services: [],
      targets: [],
      age_wise_service: {
        treatment: []
      },
      lastMonthData: {},
      showMonthFilter: false,
      showYearFilter: false,
      showCenterFilter: false,
      showPatientFilter: false,
      centers: [],
    };
  },
  created() {
    this.filter.month = moment().format('MMM');
    this.setYears();
    this.getReports();
    this.centerList();
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
  },
  methods: {
    numDifferentiation(val) {
        if(val >= 10000000) val = Math.round((val/10000000)*100)/100 + ' C';
        else if(val >= 100000) val = Math.round((val/100000)*100)/100 + ' L';
        else if(val >= 1000) val = Math.round((val/1000)*100)/100 + ' K';
        return val;
    },
    selectPeriod(type, value) {
      this.filter.period = type;
      if(type == 'monthly') {
        this.filter.quarter = '';
        this.filter.month = value;
      } else {
        this.filter.month = '';
        this.filter.quarter = value;
      }
    },
    getYearLabel(year) {
        return year?String(year).substring(2,4):''
      },
      setYears() {
      let cYear = new Date().getFullYear();
      while ( this.start_year <= cYear ) {
        this.years.push(this.start_year++);
      }   
    },
    numberFormat(num) {
      return num?(Math.round(num * 100) / 100).toFixed(2):0;
    },
    calculateProgress(achieved, target) {
      if(target > 0) {
        return Math.floor((achieved*100)/target);
      }
      return 0;
    },
    checkTargetExists(product) {
      return this.targets.some(item => item.product_type == product);
    },
    getTarget(product, fieldName) {
      let target = this.targets.find(item => item.product_type == product);
      if(target) {
        return target[fieldName];
      }
      return 0;
    },
    getAgeServiceCount(max_age, phase, fieldName) {
      if(!max_age) {
        let total = 0;
        this.age_wise_service[phase].forEach((item) => {
          total += parseFloat(item[fieldName])
        });
        return total;
      }

      let service = this.age_wise_service[phase].find(item => item.max_age == max_age);
      if(service) {
        return service[fieldName];
      }
      return 0;
    },
    getServiceCount(serviceType, fieldName) {
      if(!this.services[serviceType]) {
        return 0;
      }
      return (this.services[serviceType][fieldName])?this.services[serviceType][fieldName]:0;
    },
    handleRowClicked(index, date) {
      this.loading = true;
      let selectedCenter = null;
      if(this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }

      axios
          .get(
              `${
                  process.env.VUE_APP_API_URL
              }/api/v2/report/orkocenter/new/occ/service-detail/by-date?api_token=${localStorage.getItem(
                  "api_token"
              )}${this.filter.patientType?'&patient_type='+this.filter.patientType:''}${selectedCenter?'&institute_id='+selectedCenter.id:''}
              &date=${date}&period=${this.filter.period}`
          )
          .then((res) => {
            this.table_data = this.table_data.map(((item, key) => {
                          if(index == key) {
                            item.expand = !item.expand;
                            item.services = res.data.data.filter((product) => {
                               if(product.total_income || product.patient_count) {
                                return product;
                               }
                            });
                            item.prev_day = res.data.prev_day;
                          } else {
                            item.expand = false;
                          }
                          return item;
                        }));


            this.loading = false;
          });


    },
    calculatePercentage(currentMonth, prevMonth) {
      let diff = currentMonth - prevMonth;
      if(diff != 0 && prevMonth != 0) {
        return Math.round((diff / prevMonth * 100) * 100) / 100
        // return Math.floor(diff / prevMonth * 100);
      } else if (diff != 0 && prevMonth == 0) {
        return Math.round(diff * 100) / 100
        // return Math.floor(diff);
      } else {
        return 0;
      }
    },
    incomeCalculate(type) {
      let total = 0;
      this.table_data.forEach((item) => {
        total += item[type]
      });
      return total;
    },
    dateFormat(date) {
      if(this.filter.period == 'monthly') {
        return date?moment(date).format('D MMM'):'';
      } else {
        return date?moment(date).format('MMM YYYY'):'';
      }
    },
    labelUpdate(label) {
      if(label == 'Ponseti (Plaster)') { return 'Casting'}
      else if(label == 'Ponseti (Surgery)') { return 'Surgery' }
      else if(label == 'Ponseti (Tenotomy)') { return 'Tenotomy' }
      else if(label == 'Ponseti (Fasciotomy)') { return 'Fasciotomy' }
      else if(label == 'registration_fee') { return 'Registration' }
      else if(label == 'brace') { return 'Brace' }
      else if(label == 'appointment') { return 'Consultation' }
      else if(label == 'therapy_appointment') { return 'Consultation' }
      else if(label == 'therapy_appointment_fp') { return 'Follow-up Consultation' }
      else if(label == 'clubfoot_appointment') { return 'Consultation' }
      else if(label == 'clubfoot_appointment_fp') { return 'Follow-up Consultation' }
      else if(label == 'dental_appointment') { return 'Consultation' }
      else if(label == 'dental_appointment_fp') { return 'Follow-up Consultation' }
      else if(label == 'Dental') { return 'Procedure' }
      else if(label == 'Dental_fp') { return 'Follow-up Procedure' }
      else if(label == 'therapy_session') { return 'Session' }
      else if(label == 'therapy_session_fp') { return 'Follow-up Session' }
      else if(label == 'assistive_device') { return 'Assistive Device' }
      else if(label == 'assistive_device_fp') { return 'Assistive Device' }
      else if(label == 'Plaster Material') { return 'Casting Material' }
      else if(label == 'Plaster Material_fp') { return 'Follow-up Casting Material' }
      else if(label == 'Ponseti (Plaster)_fp') { return 'Follow-up Casting'}
      else if(label == 'Ponseti (Surgery)_fp') { return 'Follow-up Surgery' }
      else if(label == 'Ponseti (Tenotomy)_fp') { return 'Follow-up Tenotomy' }
      else if(label == 'Ponseti (Fasciotomy)_fp') { return 'Follow-up Fasciotomy' }
      else if(label == 'brace_fp') { return 'Follow-up Brace' }
      else if(label == 'booked_appointment') { return 'Consultation' }
      else if(label == 'booked_appointment_fp') { return 'Follow-up Consultation' }
      else {
        return 'Label not matched';
      }
    },
    selectFilter(type) {
      if (type == "Month") {
        this.showCenterFilter = false;
        this.showPatientFilter = false;
        this.showYearFilter = false;
        this.showMonthFilter = !this.showMonthFilter;
      } else if (type == "Year") {
        this.showCenterFilter = false;
        this.showPatientFilter = false;
        this.showMonthFilter = false;
        this.showYearFilter = !this.showYearFilter;
      } else if (type == "Center") {
        this.showMonthFilter = false;
        this.showPatientFilter = false;
        this.showCenterFilter = !this.showCenterFilter;
      } else if (type == "Patient") {
        this.showMonthFilter = false;
        this.showCenterFilter = false;
        this.showPatientFilter = !this.showPatientFilter;
      }
    },
    getReports() {
      this.loading = true;
      let selectedCenter = null;
      if(this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }
      axios
          .get(
              `${
                  process.env.VUE_APP_API_URL
              }/api/v2/report/orkocenter/new/occ/service?api_token=${localStorage.getItem(
                  "api_token"
              )}${this.filter.month?'&month='+this.filter.month:''}${selectedCenter?'&institute_id='+selectedCenter.id:''}
              ${this.filter.patientType?'&patient_type='+this.filter.patientType:''}
              &period=${this.filter.period}&year=${this.filter.year}${this.filter.quarter?'&quarter='+this.filter.quarter:''}`
          )
          .then((res) => {
            this.table_data = res.data.data.data.reverse();
            this.table_data = this.table_data.map((item => {
              item.prev_day = {};
              item.services = [];
              if(moment(item.date).isSame(moment(), 'day')) {
                item.expand = false; //TODO
                item.isToday = true;
              } else {
                item.expand = false;
                item.isToday = false;
              }
              return item;
            }))

            this.services = res.data.service_counts;
            this.lastMonthData = res.data.last_month;
            this.targets = res.data.target_counts;

            if(this.filter.patientType == 'Zero Clubfoot') {
            this.age_wise_service = res.data.age_wise_service;
            // let pCount = 0;
            // this.age_wise_service.registration = this.age_wise_service.registration.map(item => {
            //   if(item.max_age == 72) {
            //     pCount += parseInt(item.patient_count)
            //   } else if(item.max_age == 180) {
            //     item.patient_count = pCount + parseInt(item.patient_count)
            //     return item;
            //   }
            //   else {
            //     return item;
            //   }
            // }).filter(item => item);

            // let tCount = 0;
            // let tIncome = 0;
            // this.age_wise_service.treatment = this.age_wise_service.treatment.map(item => {
            //   if(item.max_age == 72) {
            //     tCount += parseInt(item.patient_count);
            //     tIncome += item.total_income
            //   } else if(item.max_age == 180) {
            //     item.patient_count = tCount + parseInt(item.patient_count)
            //     item.total_income += tIncome
            //     return item;
            //   }
            //   else {
            //     return item;
            //   }
            // }).filter(item => item);
          }
            this.loading = false;

            // let servArr = [];
            // if(this.filter.patientType == 'Zero Clubfoot') {
            //   servArr = ['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'brace', 'clubfoot_appointment'];  
            // } else if (this.filter.patientType == 'Dental') {
            //   servArr = ['Dental', 'dental_appointment', 'Dental_fp', 'dental_appointment_fp'];  
            // } else if (this.filter.patientType == 'Plaster Material') {
            //   servArr = ['Plaster Material'];  
            // } else {
            //   servArr = ['therapy_appointment', 'therapy_session', 'assistive_device', 'therapy_appointment_fp', 'therapy_session_fp', 'assistive_device_fp'];  
            // }
            
            // servArr.forEach(serv => {
            //   if(!this.services.some(item => item.product_type == serv)) {
            //     this.services.push({ product_type: serv, patient_count: 0, total_income: 0 })
            //   }
            // });

            // this.services = this.sortService(this.services, ['registration_fee', 'Ponseti (Plaster)', 'Ponseti (Tenotomy)', 'Ponseti (Surgery)', 'clubfoot_appointment', 'brace', 'therapy_appointment', 'Plaster Material', 'therapy_appointment_fp', 'therapy_session','therapy_session_fp', 'assistive_device', 'dental_appointment', 'dental_appointment_fp'])
          
          });
    },
    sortService(services, orders)
    {
        let newArr = [];
        for (let i=0; i<orders.length; i++) {
          if (services.some(e => e.product_type === orders[i])) {
            newArr.push(services.find(e => e.product_type === orders[i]))
          }
        }

        
        for (let k=0; k<services.length; k++) {
          if (!newArr.some(e => e.product_type === services[k].product_type)) {
            newArr.push(services.find(e => e.product_type === services[k].product_type))
          }
        }
        return newArr;
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              // this.loading = false
              this.centers = response.data.data
            }
          })
    },
  },
};
</script>
<style lang="scss" scoped>
.c-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  float: left;

  .filter-list {
    width: 100%;
    float: left;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      width: 33.333333%;
      float: left;
      padding: 0px 10px 0;
      border-right: 2px solid #002170;

      &:last-of-type {
        border-right: none;
        padding: 0;
      }

      select {
        border: none;
        outline: none;
        scroll-behavior: smooth;
        -webkit-appearance: listbox !important;

        color: #002170;
        font-size: 15px;
        font-weight: 600;
        font-family: Arial;
        padding: 0 12px 0 8px;
        height: 22px;
      }
    }
  }

  .target-content {
    width: 100%;
    float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #00579b;
    padding: 25px;

    h2 {
      color: #f9a91a;
      font-family: Arial;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 20px;
      width: 100%;
      float: left;

      li {
        width: 100%;
        float: left;

        .l-label {
          width: 45%;
          float: left;
        }

        .r-label {
          text-align: right;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          font-family: Arial;

          .achieved {
            color: #f9a91a;
          }
        }

        label {
          color: #fff;
          font-size: 18px;
          font-family: Arial;
          font-weight: 600;
        }
      }
    }
  }

  .timeline-tbl {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    float: left;
    .expanded-row {
      box-shadow: 0 0 0 1px #AAA;
      border-radius: 20px;
    }

    tr {
      th {
        color: #002147;
        border-bottom: 1px solid #a0a0a1;
        padding-bottom: 10px;
        text-align: center;
        font-size: 16px;
      }
    }

    tbody {
      tr {
        &.expanded-total-row {
          border-top: 1px solid #AAA;
         td {
            ul {
              margin: 6px 0;
              list-style-type: none;
              li {
                line-height: 1.6;
                &:last-of-type {
                  font-size: 13px;
                  span {
                    font-size: 13px;
                    i {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
         }
        }
        td {
          text-align: center;
          font-size: 16px;
          line-height: 2.5;
          color: #002170;
        }

        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .summary-wrapper {
    width: 100%;
    float: left;
    background-color: #00579b;
    padding: 8px 30px;
    border-radius: 20px;
    margin-top: 30px;

    .summary-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;

      tr {
        th {
          color: #fff;
          font-size: 17px;
          padding-bottom: 5px;
        }

        td {
          font-size: 15px;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}

.brd-15 {
  border-radius: 15px;
}

.ftb-15 {
  font-size: 15px;
  font-weight: 600;
}

.up-text {
  color: #00cd32;
  font-size: 15px;
  font-weight: 600;

  i {
    color: #00cd32;
    font-size: 13px;
  }
}

.down-text {
  color: red;
  font-size: 15px;
  font-weight: 600;

  i {
    color: red;
    font-size: 13px;
  }
}

.coll-btn {
  color: #002170;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial;
  padding: 0 12px 0 8px;
  height: 22px;

  background-color: transparent;
  border: none;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.month-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}

.year-selection-list {
  width: 100%;
 
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      background-color: #d9d4d4;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      margin: 5px;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}

.center-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}

.new-service-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  // margin: 0;
  li {
    width: 140px;
    float: left;
    text-align: center;
    a {
      background-color: #fff;
      width: 95%;
      display: block;
      padding: 3px 5px;
      margin: 0 auto;
      border-radius: 16px;
      color: #000;
      font-weight: 600;
      &.active {
        background-color: #FBA81D;
      }
    }
  }
}
.new-center-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 10px 0;
  padding: 0 5px;
  // margin: 0;
  li {
    width: 25%;
    float: left;
    text-align: center;
    a {
      background-color: #EBEBEB;
      width: 95%;
      display: block;
      padding: 3px 5px;
      margin: 0 auto;
      border-radius: 16px;
      color: #000;
      font-weight: 600;
      &.active {
        background-color: #3DB4E6;
        color: #fff;
      }
    }
  }
}

.tab-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 0; 
  background-color: #3DB4E6;
  border-radius: 15px;
  li {
    cursor: pointer;
    width: 33.33333333%;
    float: left;
    text-align: center;
    background-color: #3DB4E6;
    color: #fff;
    padding: 5px 5px 12px;
    border-radius: 20px 20px 0 0;
    font-weight: 600;
    &.active {
      background-color: #00579C;
      color: #fff;
    }
  }
}

.c-tab-content {
  margin-top: -10px;
  width: 100%;
  float: left;
  position: relative;
  border-radius: 15px; 
  background-color: #00579C;
  padding-bottom: 25px; 
  .c-tab-item {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin: 0; 
    padding: 8px 15px 15px;
    li {
    width: 33.33333333%;
    float: left;
    color: #fff;
    padding: 2px 10px 0 0;
    line-height: 1.2;
    // border-radius: 20px 20px 0 0;
    // font-weight: 600;
    
    &:nth-child(2), &:nth-child(3) {
      text-align: center;
    }

    &:nth-child(3) {
      padding-left: 3px;
      padding-right: 3px;
    }

    &:nth-child(2) {
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
    }

    .service-name {
        color: #F9AA1A;
        font-size: 15px;
        line-height: 1;
        font-weight: 600;
    }
    .target-text {
      display: block;
      font-size: 12px;
      font-weight: 600;
      margin-top: 2px;
    }
    .pt-count {
      color: #F9AA1A;
      font-weight: 600;
      font-size: 32px;
    }
    .money-count {
      color: #F9AA1A;
      font-weight: 600;
      font-size: 18px;
      small {
        font-weight: 600;
      }
    }
    .pt-label {
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      margin-left: 6px;
    }
  }
  }

  .c-progressbar {
    width: 85%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    color: #FFF;
    padding: 15px;
    margin: 5px 0;
    li {
      float: left;
      height: 15px;
      position: relative;
      border-right: 2px solid #00579C;
      &:last-child {
        border-right: none;
      }
      span {
        position: absolute;
        left: 0;
        font-weight: 600;
        top: -17px;
        left: 0;
        font-size: 12px;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}

.filter-list-new {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 0;
  li {
    width: 100px;
    float: right;
    text-align: center;
    button {
      color: #000;
    }
  } 
}

.patient-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 8px 30px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}


.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.patient-income-table {
  height: 475px;
  overflow: scroll;
  // padding: 2px;
}
.popover{
        max-width:360px;
    }
</style>
